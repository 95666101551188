@use 'ngx-sharebuttons/themes/default';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

html, body, mat-sidenav-container, .app-container 
{
  margin: 0;
  width: 100%;
  height: 100%;  
}

.app-content 
{
  overflow: hidden;
}

market-app 
{
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* apply a one-time font-family to everything for consistent look */
*
{
  font-family: Roboto,"Helvetica Neue",sans-serif;
}

/* dialogs */
/*
mat-dialog-content {
  max-width: 540px;
  font: 500 14px/20px Roboto,"Helvetica Neue",sans-serif;
}
*/

/* main toolbar */
/*
mat-toolbar {
  min-height: auto !important;
}

mat-toolbar-row {
  min-height: 48px;
}
*/

a:visited, a:link {
  text-decoration: none;
  color: #428bca;
}
a:hover {
  color: #428bca;
  text-decoration: underline;
}

.text-muted {
  color: #999;
  font-size: 14px;
}

.nowrap {
  display: inline-block;
  white-space: nowrap;
}

.heart-noselect {
  color: black;
}
.heart-noselect:hover {
  color: #cc0000;
}
.heart-select {
  color: #cc0000;
}
.heart-select:hover {
  color: #dd0000;
}

.badge-admin {
  color: #0089cc;
}
.badge-user {
  color: #ccaa00;
}

.arrow_box {
  display: inline-block;
  position: relative;
  background: #ffffff;
  border: 1px solid lightgray;
  margin-left: 4px;
  margin-right: 4px;
  padding-right: 6px;
  padding-left: 6px;
}
.arrow_box:after, .arrow_box:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #ffffff;
  border-width: 4px;
  margin-top: -4px;
}
.arrow_box:before {
  border-color: rgba(0, 0, 0, 0);
  border-right-color: gray;
  border-width: 5px;
  margin-top: -5px;
}

.youtube-player {
  width: 80vw !important;
  height: 50vw !important;
  max-width: 640px !important;
  max-height: 390px !important;
}

.cdk-overlay-pane .mat-dialog-container {
  z-index: 1001 !important;
  position: relative;
}

.mat-list-item-content {
  display: -webkit-flex; /* fixes older ios that doesnt support flex */
}

.mat-toolbar-row {
  display: -webkit-flex;
}

.mat-card-subtitle {
  color: rgba(0,0,0,.75)
}

share-buttons .sb-style {
  display: inline-flex;
  display: -webkit-inline-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.text-error {
  text-align: center;
  color: white;
  background: red;
  margin: 0px;
}

/*
h1 {
  color: #369;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 250%;
}
h2, h3 {
  color: #444;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
}

body, input[text], button {
  color: #888;
  font-family: Cambria, Georgia;
}
a {
  cursor: pointer;
  cursor: hand;
}
button {
  font-family: Arial;
  background-color: #eee;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  cursor: hand;
}
button:hover {
  background-color: #cfd8dc;
}
button:disabled {
  background-color: #eee;
  color: #aaa;
  cursor: auto;
}

// Navigation link styles
nav a {
  padding: 5px 10px;
  text-decoration: none;
  margin-right: 10px;
  margin-top: 10px;
  display: inline-block;
  background-color: #eee;
  border-radius: 4px;
}
nav a:visited, a:link {
  color: #607D8B;
}
nav a:hover {
  color: #039be5;
  background-color: #CFD8DC;
}
nav a.active {
  color: #039be5;
}

// everywhere else
* {
  font-family: Arial, Helvetica, sans-serif;
}
*/